// import { future } from '@theme-ui/presets'

export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 960,
    headerHeight: [100, 100, 150],
  },
  fonts: {
    body: '"Avenir Next", system-ui, sans-serif',
    heading: 'inherit',
    moonospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#A88A9C',
    secondary: '#594f3B',
    highlight: '#c8b6ba',
    muted: '#f6f6ff',
    day: '#ffead0',
    night: '#04308a',
    error: 'red',
    modes: {
      dark: {
        text: '#fff',
        background: '#000',
        primary: '#D7BCE8',
        secondary: '#5D576B',
        highlight: '#8884FF',
        muted: '#011',
      },
    },
  },
  layout: {
    main: {
      margin: '0 auto',
      width: ['100%', '95%'],
      maxWidth: 'container',
      display: 'flex',
      flexDirection: 'column',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    header: {
      marginBottom: [2, 3, 3],
      // height: theme => theme.sizes.headerHeight,
    },
    footer: {
      width: ['100%', '95%'],
      margin: '0 auto',
      maxWidth: 'container',
    },
    section: {
      padding: [2, 3, 3],
      paddingX: [3, 4, 5],
      textAlign: ['center', 'left', 'left'],
    },
    padding: {
      padding: [2, 3, 3],
      paddingX: [3, 4, 5],
    },
    cta: {
      padding: [2, 3, 3],
      paddingX: [2, 3, 5],
      textAlign: 'center',
    },
    inputGroup: {
      flex: '1 1 250px',
      px: [1, 2, 2],
      py: [1, 1, 1],
    },
    disabledInputGroup: {
      flex: '1 1 250px',
      px: [1, 2, 2],
      py: [1, 1, 1],
      opacity: '0.25',
    },
    row: {
      flexWrap: 'wrap',
    },
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      marginTop: [1, 2, 2],
      marginBottom: [2, 3, 3],
    },
    quote: {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: [2, 3, 3],
    },
    error: {
      color: 'error',
      fontStyle: 'italic',
    },
    p: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      marginTop: [1, 2, 2],
      marginBottom: [2, 3, 3],
    },
    plain: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
  link: {
    button: {
      padding: [2, 3, 3],
      backgroundColor: 'primary',
      color: 'background',
      fontSize: 'inherit',
      border: 0,
      textDecoration: 'none',
      borderRadius: 4,
      '&:hover': {
        fontWeight: 'bold',
      },
    },
    plain: {
      color: 'text',
      textDecoration: 'none',
    },
  },
  buttons: {
    primary: {
      padding: [2, 3, 3],
      backgroundColor: 'primary',
      color: 'background',
      fontSize: 'inherit',
      border: 0,
      borderRadius: 4,
      '&:hover': {
        fontWeight: 'bold',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
      marginTop: [1, 2, 2],
      marginBottom: [2, 3, 3],
      textAlign: ['center', 'left', 'left'],
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
      marginY: [2, 3, 3],
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
      marginY: [1, 2, 2],
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      marginTop: 1,
      marginBottom: 3,
      fontSize: 3,
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      marginBottom: 3,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
    img: {
      maxWidth: '100%',
    },
    ul: {
      listStylePosition: 'inside',
      marginY: 1,
      marginX: 3,
    },
    ol: {
      listStylePosition: 'inside',
      marginY: 1,
      marginX: 3,
    },
    blockquote: {
      // borderLeft: '10px solid #ccc',
      // margin: '1.5rem 1rem',
      margin: '1.5em auto',
      textAlign: 'center',
      padding: '0.5rem 1rem',
      '& > p': {
        display: 'inline',
      },
    },
  },
}
