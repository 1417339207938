/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as ThemeComponents from '@theme-ui/components'
import { Link as GatsbyLink } from 'gatsby'

const Button = ({ sx, ...props }) => (
  <button sx={{ variant: 'buttons.primary', ...sx }} {...props} />
)

const Link = props => <ThemeComponents.Link as={GatsbyLink} {...props} />

const Text = props => <ThemeComponents.Text variant="p" {...props} />

export * from '@theme-ui/components'
export { Button, Link, Text }
