import { Auth } from 'aws-amplify'

const isBrowser = typeof window !== 'undefined'

const setUser = user => (window.localStorage.taichiUser = JSON.stringify(user))

const getUser = () => {
  if (window.localStorage.taichiUser) {
    const user = JSON.parse(window.localStorage.taichiUser)
    return user ? user : {}
  }
  return {}
}

const isLoggedIn = () => {
  if (!isBrowser) {
    return false
  }
  const user = getUser()
  if (user) {
    return !!user.username
  }
}

const getCurrentUser = () => isBrowser && getUser()

const logout = async () => {
  if (!isBrowser) {
    return
  }
  await Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.error(err))
  setUser({})
}

export { setUser, isLoggedIn, getCurrentUser, logout }
