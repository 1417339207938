import Amplify, { Auth } from 'aws-amplify'
import config from './src/config'
import { setUser } from './src/utils/auth'

Amplify.configure(config.aws)

// export const onClientEntry = async (_, options) => {
//   console.log('onClientEntry', process.env.aws_project_region)
// }

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      // console.log('currentAuthenticatedUser', user)
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      setUser(userInfo)
    })
    .catch(err => {
      // console.log('auth error', err)
    })
}
