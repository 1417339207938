// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-mdx-js": () => import("./../src/templates/blog-post-mdx.js" /* webpackChunkName: "component---src-templates-blog-post-mdx-js" */),
  "component---content-pages-about-mdx": () => import("./../content/pages/about.mdx" /* webpackChunkName: "component---content-pages-about-mdx" */),
  "component---content-pages-andy-lee-mdx": () => import("./../content/pages/andy-lee.mdx" /* webpackChunkName: "component---content-pages-andy-lee-mdx" */),
  "component---content-pages-dues-mdx": () => import("./../content/pages/dues.mdx" /* webpackChunkName: "component---content-pages-dues-mdx" */),
  "component---content-pages-essential-principles-mdx": () => import("./../content/pages/essential-principles.mdx" /* webpackChunkName: "component---content-pages-essential-principles-mdx" */),
  "component---content-pages-forms-essential-mdx": () => import("./../content/pages/forms/essential.mdx" /* webpackChunkName: "component---content-pages-forms-essential-mdx" */),
  "component---content-pages-forms-index-mdx": () => import("./../content/pages/forms/index.mdx" /* webpackChunkName: "component---content-pages-forms-index-mdx" */),
  "component---content-pages-forms-sword-mdx": () => import("./../content/pages/forms/sword.mdx" /* webpackChunkName: "component---content-pages-forms-sword-mdx" */),
  "component---content-pages-forms-tai-chi-kung-mdx": () => import("./../content/pages/forms/tai-chi-kung.mdx" /* webpackChunkName: "component---content-pages-forms-tai-chi-kung-mdx" */),
  "component---content-pages-holidays-mdx": () => import("./../content/pages/holidays.mdx" /* webpackChunkName: "component---content-pages-holidays-mdx" */),
  "component---content-pages-lessons-hug-a-tree-mdx": () => import("./../content/pages/lessons/hug-a-tree.mdx" /* webpackChunkName: "component---content-pages-lessons-hug-a-tree-mdx" */),
  "component---content-pages-lessons-index-mdx": () => import("./../content/pages/lessons/index.mdx" /* webpackChunkName: "component---content-pages-lessons-index-mdx" */),
  "component---content-pages-lessons-lesson-1-mdx": () => import("./../content/pages/lessons/lesson-1.mdx" /* webpackChunkName: "component---content-pages-lessons-lesson-1-mdx" */),
  "component---content-pages-lessons-lesson-2-mdx": () => import("./../content/pages/lessons/lesson-2.mdx" /* webpackChunkName: "component---content-pages-lessons-lesson-2-mdx" */),
  "component---content-pages-lessons-lesson-3-mdx": () => import("./../content/pages/lessons/lesson-3.mdx" /* webpackChunkName: "component---content-pages-lessons-lesson-3-mdx" */),
  "component---content-pages-lessons-lesson-4-mdx": () => import("./../content/pages/lessons/lesson-4.mdx" /* webpackChunkName: "component---content-pages-lessons-lesson-4-mdx" */),
  "component---content-pages-lessons-lesson-5-mdx": () => import("./../content/pages/lessons/lesson-5.mdx" /* webpackChunkName: "component---content-pages-lessons-lesson-5-mdx" */),
  "component---content-pages-lessons-shifting-weight-mdx": () => import("./../content/pages/lessons/shifting-weight.mdx" /* webpackChunkName: "component---content-pages-lessons-shifting-weight-mdx" */),
  "component---content-pages-pine-tree-index-mdx": () => import("./../content/pages/pine-tree/index.mdx" /* webpackChunkName: "component---content-pages-pine-tree-index-mdx" */),
  "component---content-pages-policies-mdx": () => import("./../content/pages/policies.mdx" /* webpackChunkName: "component---content-pages-policies-mdx" */),
  "component---content-pages-study-mdx": () => import("./../content/pages/study.mdx" /* webpackChunkName: "component---content-pages-study-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-first-class-info-js": () => import("./../src/pages/first-class-info.js" /* webpackChunkName: "component---src-pages-first-class-info-js" */),
  "component---src-pages-index-main-bak-js": () => import("./../src/pages/index-main.bak.js" /* webpackChunkName: "component---src-pages-index-main-bak-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-members-js": () => import("./../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-schedule-js": () => import("./../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-teachers-index-js": () => import("./../src/pages/teachers/index.js" /* webpackChunkName: "component---src-pages-teachers-index-js" */),
  "component---src-pages-teachers-pinkfloor-js": () => import("./../src/pages/teachers/pinkfloor.js" /* webpackChunkName: "component---src-pages-teachers-pinkfloor-js" */)
}

