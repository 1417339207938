const aws = {
  aws_project_region: process.env.GATSBY_aws_project_region,
  aws_cognito_identity_pool_id: process.env.GATSBY_aws_cognito_identity_pool_id,
  aws_cognito_region: process.env.GATSBY_aws_cognito_region,
  aws_user_pools_id: process.env.GATSBY_aws_user_pools_id,
  aws_user_pools_web_client_id: process.env.GATSBY_aws_user_pools_web_client_id,
  oauth: {},
}

export default {
  aws,
}
